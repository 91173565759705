import BackendAuthService from "./BackendAuthService";

export default class CaracolaService extends BackendAuthService {

	constructor() {
		super();
	}

	users_list(){
		return this.httpCall('api/caracola/users',{
            method: 'GET',
		});
	}

	giftExchange(data) {
		return this.httpCall('api/caracola/gift-exchange', {
			method: 'POST',
			data
		})
	}
}

