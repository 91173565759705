<template  lang="pug">
	v-container
		v-row(justify="center")
			v-col( cols="12" lg="10" )
				v-card
					v-tabs(v-model="tab" centered)
						v-tab(v-for="item in items" :key="item") {{ item }}

					v-tabs-items(v-model="tab")
						v-tab-item 
							Caracola
						v-tab-item
							Intercambio
</template>
<script>
    import Caracola from './components/Caracola'
    import Intercambio from './components/Intercambio'
	export default {
		name: 'CaracolaIndex',
        components: {
            Caracola,
			Intercambio
		},
		data() {
			return {
				tab: null,
				items: ['Caracola', 'Intercambio'],
			}
		},
		mounted(){
		},
		methods:{
		},
	}
</script>
