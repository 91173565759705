<template  lang="pug">
    v-container
        v-row()
            v-col(cols="12" class="text-center")
                v-btn(
                    @click="play()" 
                    :disabled="isDisabledPlayBtn" 
                    :loading="loadingPlay"
                    class="mx-2 primary" ) Play
        v-row 
            v-col(class="")
                v-data-table(
                    :headers="headers"
                    :items="users"
                    :loading="loadingUsers"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    no-data-text="Sin participantes"
                    class="elevation-2")
                    template(v-slot:item.action="{ item }")
                        v-btn(
                            fab
                            dark
                            x-small
                            color="red"
                            @click="removeItem(item)")
                            v-icon(dark) mdi-trash-can-outline   
        v-snackbar(v-model="snackbar" :timeout="timeout")
            | Peticion de intercambio de regalos enviada, una correo le llegará a cada participante a lo largo del dia.
            template(v-slot:action="{ attrs }")
                v-btn(color="pink" text v-bind="attrs" @click="snackbar = false") Cerrar

                    
</template>
<script>

import CaracolaService from '../../../../services/CaracolaService'
const caracolaService = new CaracolaService();

export default {
    name: 'Intercambio',
    data() {
        return {
            users: [],
            loadingUsers: false,
            loadingPlay: false,
            isDisabledPlayBtn: false,
            timeout: 10000,
            snackbar: false,
            headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'E-mail',
                    align: 'start',
                    sortable: false,
                    value: 'email',
                },
                { text: '', value: 'action', width:"50" },
            ],
        }
    },
    methods: {
        init() {
            this.loadingUsers = true;
            caracolaService.users_list()
            .then(data => {
                this.users = data.data.users;
            })
            .finally(()=> this.loadingUsers = false);
        },
        removeItem(item) {
            this.users = this.users.filter(i => i.email != item.email)
        },
        play() {
            this.loadingPlay = true;
            caracolaService.giftExchange(this.users)
            .then(data => {
                console.log(data)
                this.isDisabledPlayBtn = true;
                this.snackbar = true;
            })
            .finally(()=> {
                this.loadingPlay = false;
            })
        }
    },
    mounted() {
        this.init();
    }
}
</script>