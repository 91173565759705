<template  lang="pug">
    v-container
        v-row()
            v-col(cols="12" class="text-center")
                v-btn(@click="select()" class="mx-2 primary" small primary) Seleccionar
                v-btn(@click="shuffleItems()" class="mx-2 secondary" small) Shuffle
        v-row 
            v-col
                v-data-table(
                    :headers="headers"
                    :items="users"
                    :loading="loadingUsers"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    no-data-text="Finalizado"
                    class="elevation-1")

                    
            v-col(class="justify-center d-flex mt-8")
                div(class="user-selected justify-center align-center")
                    span(v-if="userSelected" class="text-center") {{userSelected.name}}
            v-col
                v-data-table(
                    :headers="headers"
                    :items="usersDone"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    no-data-text="Sin datos"
                    class="elevation-1")
                    template(v-slot:item.action="{ item }")
                        v-btn(
                            fab
                            dark
                            x-small
                            class="warning"
                            @click="returnUser(item)")
                            v-icon(dark) mdi-keyboard-return  

</template>
<script>

import CaracolaService from '../../../../services/CaracolaService'
const caracolaService = new CaracolaService();

export default {
    name: 'Caracola',
    data() {
        return {
            userSelected: {},
            users: [],
            usersDone: [],
            loadingUsers: false,
            headers: [
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: '', value: 'action', width:"50" },
            ],
        }
    },
    methods: {
        init() {
            this.loadingUsers = true;
            caracolaService.users_list()
            .then(data => {
                this.users = data.data.users;
                this.shuffleItems()
            })
            .finally(()=> this.loadingUsers = false);
        },
        select() {
            if (this.users && this.users.length > 0) {
                const randomIndex = Math.floor(Math.random() * (this.users.length));
                let item = this.users.splice(randomIndex,1)
                if (typeof item[0] != 'undefined') {
                    this.userSelected = item[0];
                    this.usersDone.push(item[0])
                }
            }
        },
        shuffleItems() {
            var tempArr = this.shuffleArray(this.users)
            this.users = tempArr
        },
        //The Fisher-Yates algorith
        shuffleArray(array) {
            var tempArr = array.slice() // clonamos
            for (let i = tempArr.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = tempArr[i];
                tempArr[i] = tempArr[j];
                tempArr[j] = temp;
            }
            return tempArr;
        },
        // regresar usuario de usersDone a users
        returnUser(item){
            this.users.push(item)
            this.usersDone = this.usersDone.filter((user) => user.email != item.email)
            this.userSelected = {}
        }
    },
    mounted() {
        this.init();
    }
}
</script>


<style scoped>
    .user-selected {
        width: 150px;
        height: 150px;
        background-color: #CCC;
        border-radius: 50%;
        display: flex;
    }
</style>